import React from "react";
import { graphql } from "gatsby";

import NetworksHero from "../../components/NetworksHero";
import IconBox from "../../components/IconBox";
import ViewNext from "../../components/ViewNext";
import OriginalSeriesList from "../../components/OriginalSeriesList";
import ImageList from "../../components/ImageList";
import Divisions from "../../components/Divisions";
import HelmetInfo from "../../components/HelmetInfo";

const Production = ({ data }) => {
  const productions = data.prismicDistributionIndex.data.productions;

  let nextProductionTitle = undefined;
  let nextProductionUID = undefined;

  if (Array.isArray(productions)) {
    const index = data.prismicDistributionIndex.data.productions.findIndex(
      (production) => {
        if (production.production.document.data) {
          return (
            production.production.document.data.title ===
            data.prismicProduction.data.title
          );
        }
        return false;
      }
    );
    if (index != -1) {
      const length = productions.length;
      if (length - 1 > index) {
        const nextProduction = productions[index + 1];
        nextProductionTitle = nextProduction.production.document.data.title;
        nextProductionUID = nextProduction.production.document.uid;
      }
    }
  }

  const nextSeriesTitle = data.prismicProduction.data.body[0].items[0].series
    ? data.prismicProduction.data.body[0].items[0].series.document.data.title
    : undefined;
  const nextSeriesUID = data.prismicProduction.data.body[0].items[0].series
    ? data.prismicProduction.data.body[0].items[0].series.document.uid
    : undefined;

  return (
    <>
      <HelmetInfo page_data={data.prismicProduction.data} />
      <NetworksHero
        image={data.prismicProduction.data.hero_image}
        logo={
          data.prismicProduction.data.show_logo_on_production_page === "no"
            ? undefined
            : data.prismicProduction.data.logo
        }
        text={data.prismicProduction.data.description}
        buttons={data.prismicProduction.data.buttons}
        facebook_link={data.prismicProduction.data.facebook_link}
        instagram_link={data.prismicProduction.data.instagram_link}
        twitter_link={data.prismicProduction.data.twitter_link}
        youtube_link={data.prismicProduction.data.youtube_link}
        linkedin_link={data.prismicProduction.data.linkedin_link}
      />
      {data.prismicProduction.data.body &&
        data.prismicProduction.data.body.map((slice, index) => {
          if (slice.__typename === "PrismicProductionDataBodyInfobox") {
            return (
              <IconBox
                key={index}
                heading={slice.primary.heading}
                subheading={slice.primary.subheading}
                icons={slice.items}
              />
            );
          } else if (
            slice.__typename === "PrismicProductionDataBodyOriginalSeriesList"
          ) {
            return (
              <OriginalSeriesList
                key={index}
                heading={slice.primary.heading}
                subheading={slice.primary.subheading}
                items={slice.items}
              />
            );
          } else if (
            slice.__typename === "PrismicProductionDataBodySeriesLogosList"
          ) {
            return <ImageList key={index} items={slice.items} />;
          } else if (
            slice.__typename === "PrismicProductionDataBodyImagelist"
          ) {
            return (
              <ImageList
                key={index}
                heading={slice.primary.heading}
                items={slice.items}
              />
            );
          } else if (
            slice.__typename === "PrismicProductionDataBodyDivisions"
          ) {
            return (
              <Divisions
                heading={slice.primary.divisions_heading}
                items={slice.items}
              />
            );
          }
          return null;
        })}
      {data.prismicProduction.data.title === "Original Programming" &&
      nextSeriesTitle &&
      nextProductionUID ? (
        <ViewNext
          link={`/series/${nextSeriesUID}`}
          linkText={nextSeriesTitle}
          borderTop
        />
      ) : nextProductionTitle && nextProductionUID ? (
        <ViewNext
          link={`/productions/${nextProductionUID}`}
          linkText={nextProductionTitle}
          borderTop
        />
      ) : (
        <ViewNext link={`/news`} linkText="News" borderTop />
      )}
    </>
  );
};

export default Production;

export const query = graphql`
  query ($uid: String!) {
    prismicProduction(uid: { eq: $uid }) {
      data {
        hero_image {
          alt
          copyright
          url
          gatsbyImageData
        }
        logo {
          alt
          copyright
          url
          gatsbyImageData
        }
        show_logo_on_production_page
        description {
          richText
        }
        title
        buttons {
          button_text
          button_link {
            url
          }
        }
        homepage_link {
          link_type
          url
        }
        facebook_link {
          link_type
          url
        }
        instagram_link {
          link_type
          url
        }
        twitter_link {
          link_type
          url
        }
        youtube_link {
          link_type
          url
        }
        linkedin_link {
          link_type
          url
        }
        body {
          __typename
          ... on PrismicProductionDataBodyInfobox {
            primary {
              heading
              subheading
            }
            items {
              icon {
                url
                alt
              }
              icon_description
              icon_heading
            }
          }
          ... on PrismicProductionDataBodyOriginalSeriesList {
            items {
              series {
                document {
                  ... on PrismicSeries {
                    uid
                    data {
                      title
                      short_description
                      preview_image {
                        alt
                        copyright
                        url
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
            primary {
              heading
              subheading
            }
          }
          ... on PrismicProductionDataBodySeriesLogosList {
            items {
              series_link {
                url
              }
              series_logo {
                url
                alt
              }
            }
          }
          ... on PrismicProductionDataBodyImagelist {
            primary {
              heading
            }
            items {
              image {
                url
                alt
              }
              title1
              link {
                url
              }
            }
          }
          ... on PrismicProductionDataBodyDivisions {
            primary {
              divisions_heading
            }
            items {
              division_image {
                url
                alt
              }
              division_title
              division_description
              division_link {
                url
              }
            }
          }
        }
      }
    }
    prismicDistributionIndex {
      data {
        productions {
          production {
            document {
              ... on PrismicProduction {
                uid
                data {
                  logo {
                    url
                    alt
                  }
                  title
                  short_description
                }
              }
            }
          }
        }
      }
    }
  }
`;
